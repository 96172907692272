import React, { useContext, useState } from 'react';
import { faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { darkTheme } from 'utils/theme';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../../constants';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import { customTelegramBotPricing } from 'components/app/account/pricing/pricingDataNew';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';

export default function UpgradePlanDialog(props) {
    const { setCurrentActiveOutsideTabOutsourced } = props;

    const { config } = useContext(AppContext);
    const oneTimeCostOfCustomizedTelegramBot = "$497";
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(0);
    const workSpace = useSelector(getActiveWorkSpace);
    let user = useSelector(getLoggedInUser);
    let currentUrl = window.location.href;

    const getSelectionStyles = (selected) => {
        if (selected) {
            return {
                background: darkTheme.primary
            }
        }
        return !config.isDark ? {
            border: '1px solid black',
            background: 'white'
        } : {
            border: '1px solid white',
            background: darkTheme.rootBackground
        }
    }

    const getTextColor = (selected) => {
        if (selected) {
            return 'white'
        }
        return !config.isDark ? 'black' : 'white'
    }

    const getVerificationUrl = () => {
        let pathname = `${workSpace?._id}/platforms/telegram`
        return pathname;
    }

    const handleStripeSubscription = (item, index, isAnUpdate = false, others = {}) => {
        others['for_telegram_bot'] = true;
        props?.setPurchasingTelegramBot(true);
        APIService.billUser(
            null,
            null,
            null,
            false,
            item.plan,
            item.quantity,
            item?.trial_period_days,
            getVerificationUrl(),
            {
                updateCurrentSubscription: isAnUpdate,
                ...others,
            },
            (response, error) => {
                // setDisableButton(false);
                if (error) {
                    //   setupgradingIndex(-1);
                    toast.error(error);
                    props?.setPurchasingTelegramBot(false);
                    return;
                }
                let { data, message } = response;
                if (!isAnUpdate) {
                    window.location = data;
                }
            }
        );
    };

  

    const buyCustomizedTelegramBot = () => {
        handleStripeSubscription(customTelegramBotPricing, 0, false, {
            fullCallBackUrl: currentUrl
        });
    }

    const BotOfferings = React.forwardRef((props, ref) => {
        const { title } = props;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
                marginBottom: 10
            }}>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                        color: '#9CCC65'
                    }}
                />
                <h5>{title}</h5>
            </div>
        );
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 30,
                gap: 10,
            }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}>
                <h5
                    type={'h5'}
                    style={{
                        fontSize: 22,
                        paddingBottom: 20,
                        textAlign: 'center',
                        lineHeight: 1.5
                    }}
                    // dangerouslySetInnerHTML={{ __html: `How would you like to pay for your Customized Telegram Bot?` }}>
                    dangerouslySetInnerHTML={{ __html: `Make a One Time Payment of $497` }}>
                </h5>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        // width: '100%',
                        gap: 10,
                    }}>
                    {/* <Card
                        style={{
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '90%',
                            borderRadius: 8,
                            ...getSelectionStyles(selectedOption === 0)
                        }}
                        onClick={() => {
                            setSelectedOption(0)
                        }}>
                        <div
                            style={{
                                padding: 20,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                borderRadius: 8
                            }}>
                            {
                                selectedOption === 0 &&
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color={'white'}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        color: 'white'
                                    }}
                                />
                            }
                            <span
                                style={{
                                    color: getTextColor(selectedOption === 0),
                                    flex: 1,
                                    textAlign: 'center',
                                }}
                                dangerouslySetInnerHTML={{ __html: `Make a One Time Payment of <b>${oneTimeCostOfCustomizedTelegramBot}</b>` }}>
                            </span>
                        </div>
                    </Card> */}
                    {/* <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                            width: '89%'
                        }}>
                        <Divider orientation={'horizontal'} />
                        <span
                            style={{
                                fontSize: 13,
                                fontWeight: 'bold'
                            }}>OR
                        </span>
                        <Divider orientation={'horizontal'} />
                    </div> */}

                    {/* <Card
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '90%',
                            cursor: 'pointer',
                            borderRadius: 8,
                            ...getSelectionStyles(selectedOption === 1)
                        }}
                        onClick={() => {
                            setSelectedOption(1)
                        }}>
                        <div
                            style={{
                                padding: 20,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                                borderRadius: 8
                            }}>
                            {
                                selectedOption === 1 &&
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color={'white'}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        color: 'white'
                                    }}
                                />
                            }
                            <span
                                style={{
                                    color: getTextColor(selectedOption === 1),
                                    flex: 1,
                                    textAlign: 'center'
                                }}>Upgrade to Scheduler Pro Premium
                            </span>
                        </div>
                    </Card> */}
                    <div
                        style={{
                            width: '100%',
                            marginTop: 20
                        }}>
                        <Card
                            style={{
                                border: '1px solid #81D4FA',
                                borderRadius: 5,
                                padding: 20,
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                // alignItems: 'center',
                                gap: 20
                            }}>
                            <span><b>You Will Get</b></span>
                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li><BotOfferings title={`${selectedOption === 0 ? '1 Customized Bot' : 'As many customized Bots as possible'}`} /></li>
                                <li> <BotOfferings title={'Maintained Bot APIs'} /></li>
                                <li><BotOfferings title={'Maintained Hooks & Triggers'} /></li>
                                <li><BotOfferings title={'Use Bot as preferred choice of posting on Postly'} /></li>
                                {/* <li><BotOfferings title={'Bot is transferable upon request'} /></li> */}
                            </ul>
                        </Card>
                    </div>
                    {
                        selectedOption === 1 &&
                        <div
                            style={{
                                width: '90%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 10
                            }}>
                            <Card
                                style={{
                                    border: '1px solid #81D4FA',
                                    borderRadius: 5,
                                    padding: 20,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 10,
                                    width: '100%'
                                }}>
                                <FontAwesomeIcon
                                    icon={faStar}
                                    style={{
                                        color: '#FB8C00',
                                        marginRight: 13
                                    }}
                                />
                                <span>
                                    Plus all Benefits of the Scheduler Pro Premium Plan
                                </span>
                            </Card>
                        </div>
                    }
                    <Button
                        onClick={() => {
                            if (selectedOption === 0) {
                                buyCustomizedTelegramBot();
                            } else {
                                navigate(`${RoutePaths.BILLING_STRIPE}`)
                            }
                        }}
                        disabled={props?.purchasingTelegramBot}
                        style={{
                            minWidth: 150,
                            alignSelf: 'flex-end',
                            minHeight: 40,
                            marginTop: 50,
                            fontWeight: 'bold'
                        }}
                        variant={'primary'}
                    >
                        {
                            props?.purchasingTelegramBot ? 'Processing...' : `Continue`
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
}