import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { getBilledYearly, getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "components/common/Divider";
import WhiteLabelProduct from "./WhiteLabelProduct";
import { v4 as uuidv4 } from 'uuid';
import InfoBar from "components/common/InfoBar";
import { canLoggedInUserBeDemo } from "utils/user_utils";

const WhiteLabelStripeConnectCard = () => {
  const loggedInUser = useSelector(getLoggedInUser);
  const billedYearly = useSelector(getBilledYearly);
  const activeWorkSpace = useSelector(getActiveWorkSpace);

  const [creatingConnectedAccount, setCreatingConnectedAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [stripeConnectedAccount, setStripeConnectedAccount] = useState(undefined);
  const [retrievingConnectedAccount, setRetrievingConnectedAccount] = useState(false);
  const [businessType, setBusinessType] = useState('individual');
  const [openBusinessTypePrompt, setOpenBusinessTypePrompt] = useState(false);
  const [currency, setCurrency] = useState('usd');
  const [monthlyPrice, setMonthlyPrice] = useState(9.99);
  const [yearlyPrice, setYearlyPrice] = useState(99.99);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [processingProductUpdate, setProcessingProductUpdate] = useState(false);
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [addNewProductPrompt, setAddNewProductPrompt] = useState(false);
  const [features, setFeatures] = useState([
    {
      text: 'Feature 1',
      id: uuidv4()
    }
  ]);
  const [includeFeatures, setIncludeFeatures] = useState(false);
  const [showArchivedProducts, setShowArchivedProducts] = useState(false);
  const [tryingToCompleteProfileSetup, setTryingToCompleteProfileSetup] = useState(false);
  const isDemoAccount = canLoggedInUserBeDemo(loggedInUser);

  const handleClose = () => {
    setOpenBusinessTypePrompt(false);
  };

  const availableCurrency = [
    'usd',
    'eur',
    'gbp',
    'aud',
    'cad',
    'nzd',
    'chf',
  ]

  const createConnectedAccount = async () => {
    setCreatingConnectedAccount(true);
    APIService.createConnectedAccount(businessType, ((response, error) => {
      setCreatingConnectedAccount(false);
      if (error) {
        setErrorMessage(error.message);
        toast.error(error.message, { theme: 'colored' });
      }
      const { data } = response;
      window.location.href = data;
    }));
  };

  const fetchConnectedAccount = async () => {
    setRetrievingConnectedAccount(true);
    APIService.fetchStripeConnectedAccount(((response, error) => {
      setRetrievingConnectedAccount(false);
      if (error) {
        setErrorMessage(error.message);
        toast.error(error.message, { theme: 'colored' });
      }
      const { data } = response;
      setStripeConnectedAccount(data);
    }));
  };

  const createProductKeyFromName = (name) => {
    return name.toLowerCase().replace(/ /g, '_');
  }

  const addNewProduct = () => {
    if (!productName || !productDescription || !monthlyPrice || !yearlyPrice || !currency) {
      toast.error('Please fill all fields', { theme: 'colored' });
      return;
    }
    let featuresArray = [];
    if (includeFeatures) {
      featuresArray = features.map(x => x.text);
    }
    setProcessingProductUpdate(true);
    let requestBody = {
      name: productName,
      description: productDescription,
      monthly_price: monthlyPrice,
      yearly_price: yearlyPrice,
      currency: currency,
      features: featuresArray,
      includeFeatures: includeFeatures,
      // workspace_id: activeWorkSpace['_id'],
      owner: loggedInUser['_id'],
      tag: 'white_label',
      key: createProductKeyFromName(productName)
    }
    APIService.addNewProduct(requestBody, (response, error) => {
      setProcessingProductUpdate(false);
      if (error) {
        toast.error(error.message, { theme: 'colored' });
        return;
      }
      toast.success(response.message, { theme: 'colored' });
      setAddNewProductPrompt(false);
      window.location.reload();
    });
  }

  const fetchAllProductsAndPrices = () => {
    setFetchingProducts(true);
    APIService.fetchAllProductsAndPrices((response, error) => {
      setFetchingProducts(false);
      if (error) {
        toast.error(error.message, { theme: 'colored' });
        return;
      }
      const { data } = response;
      setProducts(data);
      setOriginalProducts(data);
    });
  }

  const redirectToOnboardingLink = () => {
    APIService.redirectUserToStripeConnectAccountOnboardingLink((response, error) => {
      if (error) {
        toast.error(error.message, { theme: 'colored' });
        return;
      }
      const { data } = response;
      window.location.href = data;
    });

  }


  useEffect(() => {
    fetchConnectedAccount();
  }, [])
  useEffect(() => {
    if (stripeConnectedAccount && stripeConnectedAccount?.charges_enabled) {
      fetchAllProductsAndPrices();
    }
  }, [stripeConnectedAccount])

  const displayFeatureControl = () => {
    return features.map((feature, index) => {
      const isLast = index === features.length - 1;
      return <Flex
        alignItems={'center'}
        gap={2}
      >
        <Form.Control
          placeholder={`Feature ${index + 1}`}
          type="text"
          value={feature?.text}
          onChange={(e) => {
            let newFeatures = features;
            setFeatures([...newFeatures.map(x => {
              if (x.id === feature.id) {
                return {
                  ...x,
                  text: e.target.value
                }
              }
              return x;
            }
            )]);
          }}
        />
        <Button
          variant="danger"
          size="sm"
          title="Remove feature"
          onClick={() => {
            if (features.length === 1) {
              toast.error('You must have at least one feature. You should turn off features if you do not wish to have features enabled.', { theme: 'colored' });
              return;
            }
            let newFeatures = features;
            setFeatures([...newFeatures.filter(x => x.id !== feature.id)]);
          }}
        >
          <FontAwesomeIcon
            icon='times'
          />
        </Button>
        {
          isLast && <Button
            variant="primary"
            size="sm"
            title="Add feature"
            onClick={() => {
              if (features?.length >= 5) {
                toast.error('You can only add 5 features', { theme: 'colored' });
                return;
              }
              let newFeatures = features;
              setFeatures([...newFeatures, {
                text: ``,
                id: uuidv4()
              }]);
            }}
          >
            <FontAwesomeIcon
              icon='plus'
            />
          </Button>
        }
      </Flex>
    })
  }

  const displayProducts = () => {
    return products.map((product, index) => {
      if (!showArchivedProducts && !product?.active) {
        return null;
      }
      const isLast = index === products.length - 1;
      let monthlyPrice = product?.prices?.find(x => x?.recurring?.interval === 'month')
      if (monthlyPrice) {
        monthlyPrice = monthlyPrice?.unit_amount / 100
      }
      let yearlyPrice = product?.prices?.find(x => x?.recurring?.interval === 'year')
      if (yearlyPrice) {
        yearlyPrice = yearlyPrice?.unit_amount / 100
      }
      let features;
      let includeFeatures = product?.metadata?.includeFeatures == 'true' ? true : false;
      if (product?.features) {
        features = product?.features.map((feature, index) => {
          return {
            text: feature?.name,
            id: uuidv4()
          }
        })
      }
      return <>
        <WhiteLabelProduct
          key={index}
          product={product}
          fetchAllProductsAndPrices={fetchAllProductsAndPrices}
          addNewProduct={addNewProduct}
          monthlyPrice={monthlyPrice}
          yearlyPrice={yearlyPrice}
          features={features}
          includeFeatures={includeFeatures}
          isLast={isLast}
        />
        {
          !isLast && <Divider />
        }
      </>
    })
  }

  if (stripeConnectedAccount) {
    return (
      <Col md>
        <Card
          className='mb-3' id='stripe-connect'
        >
          <FalconCardHeader
            title={`Stripe Connect settings`}
            endEl={
              <Badge pill bg="200" className={`text-${stripeConnectedAccount?.charges_enabled ? 'success' : 'danger'} fs--2`}>
                <FontAwesomeIcon
                  icon={stripeConnectedAccount?.charges_enabled ? 'check' : 'times'}
                  className="me-1" />
                {stripeConnectedAccount?.charges_enabled ? 'Connected' : 'Incomplete setup'}
              </Badge>
            }
          />
          <Card.Body className="bg-light">
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-2">
              <p className="mb-0">Account ID:</p>
              <p className="mb-0"> {stripeConnectedAccount.id}</p>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-2">
              <p className="mb-0">Account email:</p>
              <p className="mb-0">{stripeConnectedAccount.email}</p>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-2">
              <p className="mb-0">Account business name:</p>
              <p className="mb-0">{stripeConnectedAccount?.business_profile?.name}</p>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-2">
              <p className="mb-0">Account charges enabled:</p>
              <p className="mb-0">{stripeConnectedAccount.charges_enabled ? 'Yes' : 'No'}</p>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-2">
              <p className="mb-0">Account payouts enabled:</p>
              <p className="mb-0">{stripeConnectedAccount.payouts_enabled ? 'Yes' : 'No'}</p>
            </Flex>
            <Divider />
            {
              stripeConnectedAccount?.charges_enabled &&
              <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                  direction={'column'}
                >
                  <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>Manage stripe account</p>
                  <p className="mb-0 fs--2">
                    See more details about your stripe account
                  </p>
                </Flex>
                <Button
                  variant="outline-primary"
                  size="sm"
                  href={`https://dashboard.stripe.com/${stripeConnectedAccount.id}`}
                  target="_blank">
                  Manage account
                </Button>
              </Flex>
            }
            {
              stripeConnectedAccount?.charges_enabled &&
              <Divider />
            }

            {
              stripeConnectedAccount?.charges_enabled &&
              <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                  direction={'column'}
                >
                  <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                    Add a new product
                  </p>
                  <p className="mb-0 fs--2">
                    Add a new product to your white label account
                  </p>
                </Flex>
                <Flex
                >
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => setAddNewProductPrompt(true)}
                  >
                    Add product
                  </Button>
                </Flex>
              </Flex>
            }
            {
              stripeConnectedAccount?.charges_enabled &&
              <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                  direction={'column'}
                >
                  <Form.Check
                    checked={showArchivedProducts}
                    type="switch"
                    id="show-archived-products"
                    label={"Show archived products"}
                    className="mb-0 fs--1"
                    onChange={(e) => {
                      setShowArchivedProducts(e.target.checked);
                    }}
                  />
                  <p className="mb-0 fs--2">
                    View all archived products
                  </p>
                </Flex>
                <Flex
                >

                </Flex>
              </Flex>
            }
            {
              stripeConnectedAccount?.charges_enabled &&
              <Divider />
            }
            {
              stripeConnectedAccount?.charges_enabled ?
                displayProducts()
                :
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  className={'mt-3'}
                  direction={'column'}
                  gap={2}
                >
                  <InfoBar info={`Charges are not enabled on your account yet. Please complete your profile setup on stripe to enable charges. Deadline is ${new Date(stripeConnectedAccount?.requirements?.current_deadline * 1000).toLocaleDateString()}`} />
                  <Button
                    onClick={() => {
                      setTryingToCompleteProfileSetup(true);
                      redirectToOnboardingLink();
                    }}
                    disabled={tryingToCompleteProfileSetup}
                    variant="primary"
                    size="sm"
                  >
                    {
                      tryingToCompleteProfileSetup ? 'Please wait...' : 'Complete profile setup'
                    }
                  </Button>
                </Flex>
            }
          </Card.Body>
        </Card>
        <Modal show={addNewProductPrompt}
          onHide={() => !processingProductUpdate && setAddNewProductPrompt(false)}
          onExit={() => !processingProductUpdate && setAddNewProductPrompt(false)}
          centered
          size='lg'>
          <Modal.Body>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
              <Flex
                direction={'column'}
              >
                <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                  Product name
                </p>
                <p className="mb-0 fs--2">
                  This will be displayed on the invoices of your customers
                </p>
              </Flex>
              <Flex
              >
                <Form.Control
                  // style={{ width: 100 }}
                  placeholder="Product name"
                  type="text"
                  value={productName}
                  onChange={(e) => {
                    setProductName(e.target.value);
                  }}
                />
              </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
              <Flex
                direction={'column'}
              >
                <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                  Product description
                </p>
                <p className="mb-0 fs--2">
                  This will be displayed on the invoices of your customers
                </p>
              </Flex>
              <Flex
              >
                <Form.Control
                  placeholder="Product description"
                  type="text"
                  value={productDescription}
                  onChange={(e) => {
                    setProductDescription(e.target.value);
                  }}
                />
              </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
              <Flex
                direction={'column'}
              >
                <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>Monthly subscription</p>
                <p className="mb-0 fs--2">
                  Set the monthly subscription price
                </p>
              </Flex>
              <Flex
              >
                <Form.Control
                  style={{ width: 100 }}
                  type="number"
                  value={monthlyPrice}
                  onChange={(e) => {
                    setMonthlyPrice(e.target.value);
                  }}
                />
                <Form.Select
                  value={currency}
                  style={{ width: 100 }}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}>
                  {
                    availableCurrency?.map(x => {
                      return <option value={x}>{x?.toLocaleUpperCase()}</option>
                    })
                  }
                </Form.Select>
              </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
              <Flex
                direction={'column'}
              >
                <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>Yearly subscription</p>
                <p className="mb-0 fs--2">
                  Set the yearly subscription price
                </p>
              </Flex>
              <Flex
              >
                <Form.Control
                  style={{ width: 100 }}
                  type="number"
                  value={yearlyPrice}
                  onChange={(e) => {
                    setYearlyPrice(e.target.value);
                  }}
                />
                <Form.Select
                  value={currency}
                  style={{ width: 100 }}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}>
                  {
                    availableCurrency?.map(x => {
                      return <option value={x}>{x?.toLocaleUpperCase()}</option>
                    })
                  }
                </Form.Select>
              </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'start'} className="mb-3">
              <Flex
                direction={'column'}
              >
                <Form.Check
                  checked={includeFeatures}
                  type="switch"
                  id="include-features"
                  label={"Include features"}
                  className="mb-0 fs--1"
                  onChange={(e) => {
                    setIncludeFeatures(e.target.checked);
                  }}
                />
                <p className="mb-0 fs--2">
                  Add features to your product to make it more attractive (optional)
                </p>
              </Flex>
              <Flex
                direction={'column'}
                style={{ gap: 10 }}
              >
                {
                  includeFeatures ?
                    displayFeatureControl()
                    :
                    null
                }
              </Flex>
            </Flex>
            <Flex
              justifyContent={'end'}
              className='mt-3'
            >
              <Button
                variant="outline-primary"
                size="sm"
                onClick={addNewProduct}
                disabled={processingProductUpdate}
              >
                {
                  processingProductUpdate ? 'processing...' : 'Add new product'
                }
              </Button>
            </Flex>
          </Modal.Body>
        </Modal>
      </Col>
    );
  }

  return (
    <Col md>
      <Card className='mb-3' id='stripe-connect'>
        <FalconCardHeader title="Stripe Connect settings" />
        <Card.Body className="bg-light">
          <Button
            onClick={() => {
              // if (!isDemoAccount) {
              //   return toast.info('Feature is coming soon.', { theme: 'colored' });
              // }
              handleClose();
              createConnectedAccount();
            }}
            autoFocus
            disabled={creatingConnectedAccount}
          >
            {creatingConnectedAccount ? 'Connecting...' : 'Connect Stripe'}
          </Button>
          {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default WhiteLabelStripeConnectCard;
