import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faHashtag, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import {
  getPlatformFirstComment,
  setPlatformFirstComment,
} from "redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import StringUtils from "utils/string";
import { isFreeWorkspace } from "utils/workspace_utils";

const PlatformFirstComment = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const platformFirstComment = useSelector(getPlatformFirstComment);
  const workSpace = useSelector(getActiveWorkSpace);
  const [openEditor, setOpenEditor] = useState(false);
  const [openFirstCommentBox, setOpenFirstCommentBox] = useState(false);
  const [firstCommentText, setFirstCommentText] = useState();

  const userHasFreeWorkspace = isFreeWorkspace(workSpace);

  const handleTextChange = (e) => {
    const value = e.target.value;
    setFirstCommentText(value);
  };

  const handleAddFirstComment = () => {
    const newComment = {
      identifier: props.identifier,
      id: StringUtils.generateRandomString(10),
      firstCommentText,
    };
    const existingCommentIndex = platformFirstComment?.findIndex(
      (x) => x.identifier === props.identifier
    );
    if (existingCommentIndex !== -1) {
      const updatedComments = [...platformFirstComment];
      updatedComments[existingCommentIndex] = {
        ...updatedComments[existingCommentIndex],
        firstCommentText,
      };
      dispatch(setPlatformFirstComment(updatedComments));
      setOpenEditor(false);
    } else {
      dispatch(setPlatformFirstComment([...platformFirstComment, newComment]));
      setOpenEditor(false);
    }
  };

  const handleOpenEditor = () => {
    if (userHasFreeWorkspace) {
      dispatch(setPopupUpgradeAlert(true));
      setOpenEditor(false);
      return;
    }
    setOpenEditor(true);
  };

  return (
    <>
      {openEditor ? (
        <Flex>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={3}
                style={{
                  padding: "10px",
                  width: "100%",
                }}
                placeholder="Type first comment"
                onChange={(e) => {
                  handleTextChange(e);
                }}
                value={firstCommentText}
              />
            </Form.Group>
          </div>

          <Flex className={"gap-2"} alignItems={"end"}>
            <Button
              variant="default"
              size="sm"
              onClick={() => {
                setOpenEditor(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                handleAddFirstComment();
              }}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Button
            onClick={handleOpenEditor}
            // onClick={() => {
            //   setOpenEditor(true);
            // }}
            variant={"default"}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              className={"gap-2 p-2"}
            >
              <div style={{ position: "relative" }}>
                <FontAwesomeIcon className="text-primary" icon={faMessage} />
                <FontAwesomeIcon
                  icon={faHashtag}
                  className="text-primary"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    margin: "auto",
                    marginTop: "39%",
                    width: 8,
                    height: 8,
                    zIndex: 1,
                  }}
                />
              </div>
              <span className="fs--1 text-start p-1">
                {firstCommentText
                  ? firstCommentText
                  : `Click here to add your First Comment on ${StringUtils.convertSnakeCaseToTitleCase(
                      props.identifier
                    )} (optional)`}
              </span>
              {firstCommentText && (
                <Flex
                  alignItems={"center"}
                  className={"flex-1 gap-2"}
                  justifyContent={"end"}
                >
                  <span className="fs--1">Edit</span>
                  <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                </Flex>
              )}
            </Flex>
          </Button>
        </>
      )}
    </>
  );
};

export default PlatformFirstComment;
