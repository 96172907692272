import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import Trends from './Trends';
import Events from 'components/pages/workspace/events/Events';
import FeedContent from './FeedContent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveWorkSpace,
  getPanelVisibilityChoices,
  getRightBarSectionsPositions, setSearchHint
} from 'redux/slices/workspaceslice';
import APIService from 'http/api_service';
import {
  canRefreshPopularDays,
  getCachedPosts,
  getCanRefreshTrends,
  getFailedPostData,
  getPostBatchData,
  getSelectedPlatforms,
  setFailedPostData,
  setMunchedPostTargetIds,
  setOpenPostDetailsDialog,
  setPostBatchData,
  setRefreshPopularDays,
  setSelectAllAudience,
  setSelectedPlatforms,
  getTriggerFeedPost
} from 'redux/slices/postslice';
import StringUtils from 'utils/string';
import PostFailedReasonDialog from './post_failed_reason_dialog';
import { RoutePaths, Strings } from '../../../../constants';
import { getLoggedInUser } from 'redux/slices/user_slice';
import CreatePost, { freeUpLocallyCachedPlatforms } from './CreatePost';
import PostAudiencePage from './audience';
import PostPreviewer from './previewer';
import { isFreeWorkspace } from 'utils/workspace_utils';
import { useNavigate } from 'react-router-dom';
import { isAccountUnderAppSumo } from 'utils/user_utils';
import { setFreshNotificationPopup } from 'redux/slices/notification_slice';
import EmergencyNotice from 'components/common/EmergencyNotice';


const Feed = (props) => {
  const { postType } = props;
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const workSpace = useSelector(getActiveWorkSpace);
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [events, setEvents] = useState([]);
  const loadingEvents = useSelector(canRefreshPopularDays);
  const refreshTrends = useSelector(getCanRefreshTrends);
  const panelPositions = useSelector(getRightBarSectionsPositions);
  const triggerFeed = useSelector(getTriggerFeedPost);
  const [children, setChildren] = useState({});
  const [visibilityOptions, setVisibilityOptions] = useState({});
  const panelVisibilityChoices = useSelector(getPanelVisibilityChoices);
  const failedPostData = useSelector(getFailedPostData);
  const postBatchData = useSelector(getPostBatchData)
  const cachedPosts = [...useSelector(getCachedPosts)];
  const selectedPlatforms = useSelector(getSelectedPlatforms);
  const [selectPlatformScreen, setSelectPlatformScreen] = useState('small');
  const navigate = useNavigate();

  useEffect(() => {
    const landedOnAuthPage = localStorage.getItem('user_just_uploaded_bulk_content');
    if (landedOnAuthPage) {
      localStorage.removeItem('user_just_uploaded_bulk_content');
      // check if user is under ltd;
      if (isAccountUnderAppSumo(loggedInUser) && loggedInUser?.active_plan?.Key !== "LTD UPGRADE") {
        dispatch(setFreshNotificationPopup({
          show: true,
          message: `Congratulations 🎉 You can unlock unlimited Bulk posts to boost your campaigns for a one-time fee.`,
          header: ` Hi there 👋`,
          optionToDisableFutureNotifications: true,
          disableFutureNotificationsKey: Strings.MODAL_DEACTIVATION_FOR_LTD0X2,
          routeOnAcceptance: RoutePaths.BILLING_STRIPE,
          type: 'info'
        }))
      }
    }
  }, [])


  // console.log("free ws?", isFreeWorkspace(workSpace))

  const handleCancelChanges = (evt) => {
    dispatch(setSelectedPlatforms([]));
    dispatch(setMunchedPostTargetIds([]));
    dispatch(setSelectAllAudience(false));
    setTimeout(() => {
      freeUpLocallyCachedPlatforms();
      // setCurrentPanel(defaultPanel);
    }, 100);
  }

  const handleSavedChanges = (evt) => {
    // setCurrentPanel(defaultPanel);
  }

  useEffect(() => {
    let pathName = window.location.pathname;
    pathName = StringUtils.substringAfterLast(pathName, "/posts/");
    if (pathName && pathName.length === 24) {
      dispatch(setOpenPostDetailsDialog({ open: true, data: pathName }));
    }
    // if (WorkSpaceManager.isFreeWorkspace() || WorkspaceStats.isLTD(activePlan)) {
    //   window.$crisp?.push(['do', 'chat:hide']);
    // }
  }, [window.location]);

  const componentEnabled = (name) => {
    let panelChoices = workSpace?.panel_visibility_choices ?? {};
    let userPanelChoices = panelChoices[loggedInUser._id] ?? { [name]: true };
    if (userPanelChoices.hasOwnProperty(name)) {
      return userPanelChoices[name]
    } else {
      return true;
    }
  }

  const updateVisibilityOptions = (option) => {
    setVisibilityOptions((prevOptions) => {
      prevOptions = prevOptions ?? {};
      prevOptions[option] = true;
      return { ...prevOptions };
    });
  }

  const fetchTrendingTopics = () => {
    APIService.fetchTendingTopics(workSpace._id, (response, error) => {
      updateVisibilityOptions("TRENDS");
      if (response) {
        let { data } = response;
        if (data) {
          setTrendingTopics(data);
          setChildren((prevChildren) => {
            prevChildren = prevChildren ?? {};
            prevChildren[panelPositions["TRENDS"]] = <Trends title="Trending" trends={data} />;
            return { ...prevChildren };
          });
        }
      }
    });
  }

  const fetchUpcomingAndPopularDays = () => {
    APIService.fetchUpcomingPopularEvents(workSpace._id, (response, error) => {
      dispatch(setRefreshPopularDays(false));
      updateVisibilityOptions("EVENTS");
      if (response) {
        let { data } = response;
        if (data) {
          data = data.filter((event) => new Date(event.start) >= new Date());
          data = data.sort((a, b) => new Date(a.start) - new Date(b.start));
        }
        setEvents(data);
        setChildren((prevChildren) => {
          prevChildren = prevChildren ?? {};
          prevChildren[panelPositions["EVENTS"]] = <Events cardTitle="Events" events={data} />;
          return { ...prevChildren };
        });
      }
    });
  }

  useEffect(() => {
    if (workSpace) {
      dispatch(setSearchHint("Search posts..."));
      if (componentEnabled("TRENDS")) {
        fetchTrendingTopics();
      } else {
        updateVisibilityOptions("TRENDS");
      }
    }

  }, [refreshTrends]);

  useEffect(() => {
    if (workSpace) {
      if (componentEnabled("EVENTS")) {
        fetchUpcomingAndPopularDays();
      } else {
        updateVisibilityOptions("EVENTS");
      }
    }
  }, [loadingEvents]);

  useEffect(() => {
    let eventsPositions = panelPositions["EVENTS"];
    let trendingPositions = panelPositions["TRENDS"];
    if (Object.entries(children).length > 1) {
      let childrenCopy = { ...children };
      childrenCopy[eventsPositions] = <Events cardTitle="Events" events={events} />;
      childrenCopy[trendingPositions] = <Trends title="Trending" trends={trendingTopics} />
      setChildren({ ...childrenCopy });
    }
  }, [panelPositions]);

  useEffect(() => {
    let eventsPositions = panelPositions["EVENTS"];
    let trendingPositions = panelPositions["TRENDS"];
    let vCopy = { ...panelVisibilityChoices };
    let loggedInUserChoices = vCopy[loggedInUser?._id] ?? { "TRENDS": true, "EVENTS": true };
    let childrenCopy = { ...children };
    if (loggedInUserChoices.hasOwnProperty("EVENTS")) {
      if (!loggedInUserChoices["EVENTS"]) {
        delete childrenCopy[eventsPositions];
      }
    }
    if (loggedInUserChoices.hasOwnProperty("TRENDS")) {
      if (!loggedInUserChoices["TRENDS"]) {
        delete childrenCopy[trendingPositions];
      }
    }
    setChildren({ ...childrenCopy });
  }, [panelVisibilityChoices]);

  const goBack = () => {

  }

  useEffect(() => {
    if (isFreeWorkspace(workSpace)) {
      navigate(RoutePaths.MANDATORY_PLAN_UPGRADE)
    }
  }, [])

  return (
    <>
      {/* <EmergencyNotice
        notice={`You may experience certain issues on the platform. We are currently working on fixing them. Thank you for your patience.`}
      /> */}
      <Row className="g-0 g-lg-3">
        <Col lg={3}>
          <PostAudiencePage
            previouslySelectedPlatforms={selectedPlatforms}
            goBack={goBack}
            handleCancelChanges={handleCancelChanges}
            handleSavedChanges={handleSavedChanges}
            selectedPlatformsHandler={(userSelectedPlatforms) => {
              dispatch(setSelectedPlatforms(userSelectedPlatforms));
            }}
            selectPlatformScreen={selectPlatformScreen}
          />
        </Col>
        {/* <Col lg={8}> */}
        {/* <FeedContent
            postType={postType}
          /> */}
        {/* </Col> */}
        <Col lg={5}>
          <CreatePost />
        </Col>
        <Col lg={4}>
          <PostPreviewer />
        </Col>
        {/* <Col lg={4}>
          {Object.entries(children).sort(x => x[0]).map(x => x[1])}
        </Col> */}
      </Row>
      <Modal
        show={failedPostData !== null}
        onHide={() => {
          dispatch(setFailedPostData(null));
        }}
        onExit={() => {
          dispatch(setFailedPostData(null));
        }}
      >
        <PostFailedReasonDialog
          modalClose={() => {
            dispatch(setFailedPostData(null));
          }}
        />
      </Modal>
    </>
  );
};

export default Feed;
