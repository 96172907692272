import axios from "axios";
import EndPoints from "./endpoints";
import HttpSuccessDataHandler from "../utils/http_success_data_handler";
import HttpErrorHandler from "../utils/http_error_handler";
import { AuthMethod } from "../constants";
import { generateQueryFromFilterOptions } from "../utils/url";
import io from "socket.io-client";
import StringUtils from "../utils/string";
import firebase from "../firebase";
import { promisify } from "util";
import numberToWordConverter from "number-to-words";
import { getFileType } from "../utils/file_utils";
import { excludeUserFromMetrics, getLoggedInUserFromLocalStorage } from "utils/user_utils";

export default class APIService {

  static withQueryParameters(endPointURL, query) {
    const url = new URL(endPointURL);
    const searchParams = new URLSearchParams();
    if (query) {
      for (const [key, value] of Object.entries(query ?? {})) {
        if (typeof value === 'object') {
          searchParams.append(key, JSON.stringify(value));
        } else {
          searchParams.append(key, value);
        }
      }
      url.search = searchParams.toString();
    }
    return url.toString();
  }

  static async fetchCountries(cb) {
    axios
      .get(EndPoints.SUPPORTED_COUNTRIES, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchYouTubeVideoCategories(workSpaceId, regionCode, cb) {
    axios
      .get(
        EndPoints.YOUTUBE_VIDEO_CATEGORIES.replace(
          ":workspace_id",
          workSpaceId
        ).replace(":regionCode", regionCode),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async signUpWithMagicLink(email, cb) {
    let requestBody = { email, auth_method: AuthMethod.PASSWORD_LESS };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async validatePasswordLessConfirmationCode(code, owner_email, cb) {
    let requestBody = { confirmation_code: code, owner_email };
    axios
      .post(EndPoints.CONFIRM_PASSWORD_LESS_CONFIRMATION_CODE, requestBody)
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async checkUserWorkSpaces(apiKey, cb) {
    let requestBody = { apiKey };
    axios
      .post(EndPoints.DOES_USER_HAVE_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUserWorkSpaces(cb) {
    axios
      .get(`${EndPoints.USER_WORKSPACES}?fromWeb=true`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUserWorkSpacesSync() {
    try {
      let response = await axios.get(`${EndPoints.USER_WORKSPACES}?fromWeb=true`, {
        headers: await this.getRequestHeaders(),
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async validateUserAgainstSubdomain(apiKey, cb) {
    axios
      .get(`${EndPoints.USER_WORKSPACES}?fromWeb=true`, {
        headers: {
          "X-API-Key": apiKey
        },
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchTeam(workSpaceId, cb) {
    axios
      .get(EndPoints.TEAM.replace(":workspace_id", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async inviteTeamMember(workSpaceId, email, role, shouldSendEmail, cb) {
    axios
      .post(
        EndPoints.INVITE_TEAM_MEMBER.replace(":workspace_id", workSpaceId),
        { email, role, shouldSendEmail },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateMemberRole(workSpaceId, memberId, role, cb) {
    axios
      .post(
        EndPoints.UPDATE_MEMBER_ROLE.replace(":workspace_id", workSpaceId),
        { memberId, role },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async acceptTeamInvite(token, cb) {
    axios
      .post(
        EndPoints.ACCEPT_TEAM_INVITE,
        { token },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPendingTeamInvites(workSpaceId, cb) {
    axios
      .get(
        EndPoints.PENDING_TEAM_INVITES.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async cancelPendingTeamInvite(workSpaceId, token, cb) {
    axios
      .delete(
        EndPoints.CANCEL_PENDING_TEAM_INVITE.replace(
          ":workspace_id",
          workSpaceId
        ).replace(":token", encodeURIComponent(token)),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async removeTeamMember(workSpaceId, team_member_id, cb) {
    axios
      .post(
        EndPoints.REMOVE_TEAM_MEMBER.replace(":workspace_id", workSpaceId),
        { team_member_id },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchMyWorkSpaceRole(workSpaceId, cb) {
    axios
      .get(EndPoints.MY_WORKSPACE_ROLE.replace(":workspace_id", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpaceOwner(workSpaceId, cb) {
    axios
      .get(EndPoints.WORKSPACE_OWNER.replace(":workspace_id", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpaceDetailsFromInviteToken(token, cb) {
    axios
      .get(
        EndPoints.WORKSPACE_DETAILS_FROM_INVITE_TOKEN.replace(
          ":invite_token",
          token
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpaceSync(workSpaceId, apiKey) {
    try {
      const response = await axios.get(
        `${EndPoints.WORKSPACE}/${workSpaceId}`,
        {
          headers: {
            "X-API-Key": apiKey,
          },
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async fetchWorkSpace(workSpaceId, cb) {
    axios
      .get(`${EndPoints.WORKSPACE}/${workSpaceId}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async logInManually(email, password, cb) {
    let requestBody = {
      email,
      auth_method: AuthMethod.PASSWORD,
      password,
      fromWeb: true
    };
    axios
      .post(EndPoints.SIGN_IN, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async logInDespiteAlreadyExistingAccountError(email, crossReferenceSignInObject, cb) {
    let requestBody = {
      email,
      auth_method: AuthMethod.FACEBOOK_AUTH,
      crossReferenceSignInObject,
      fromWeb: true
    };
    axios
      .post(EndPoints.SIGN_IN, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async verifyNewEmailAddress(token, cb) {
    let requestBody = { token };
    axios
      .post(EndPoints.VERIFY_NEW_EMAIL_ADDRESS, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async logOut(cb) {
    axios
      .get(EndPoints.LOG_OUT, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => { })
      .catch((e) => {
        console.log(e);
      });
  }

  static async createAccountManually(email, password, displayName, cb) {
    let requestBody = {
      email,
      password,
      displayName,
      auth_method: AuthMethod.PASSWORD,
    };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updatePersonalInfo(data, cb) {
    let requestBody = { data };
    axios
      .put(EndPoints.UPDATE_PERSONAL_INFO, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async checkIfEmailIsVerified(cb) {
    axios
      .get(EndPoints.CHECK_IF_EMAIL_IS_VERIFIED, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async requestCodeForEmailVerification(
    requestBody,
    processing_signup = 0,
    cb
  ) {
    axios
      .put(
        `${EndPoints.REQUEST_CODE_FOR_EMAIL_VERIFICATION}?processing_signup=${processing_signup}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async confirmCodeForEmailVerification(
    requestBody,
    processing_signup = 0,
    cb
  ) {
    axios
      .put(
        `${EndPoints.CONFIRM_CODE_FOR_EMAIL_VERIFICATION}?processing_signup=${processing_signup}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateTitleInWorkspace(data, cb) {
    let requestBody = { ...data };
    axios
      .put(EndPoints.UPDATE_TITLE_IN_WORKSPACE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateSecurityInfo(oldPassword, newPassword, cb) {
    let requestBody = { newPassword, oldPassword };
    axios
      .put(EndPoints.UPDATE_ACCOUNT_PASSWORD, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createGoogleAccount(
    email,
    displayName,
    photoURL,
    token,
    uid,
    cb
  ) {
    let requestBody = {
      email,
      displayName,
      auth_method: AuthMethod.GOOGLE_AUTH,
      photoURL,
      accessToken: token,
      uid,
    };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createFacebookAccount(
    email,
    displayName,
    photoURL,
    token,
    uid,
    cb
  ) {
    let requestBody = {
      email,
      displayName,
      auth_method: AuthMethod.FACEBOOK_AUTH,
      photoURL,
      accessToken: token,
      uid,
    };
    axios
      .post(EndPoints.SIGN_UP, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async initiatePasswordReset(email, workSpaceId, cb) {
    let requestBody = { email, workSpaceId };
    axios
      .post(EndPoints.INITIATE_PASSWORD_RESET, requestBody)
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async finalizePasswordReset(token, newPassword, cb) {
    let requestBody = { token, newPassword };
    axios
      .post(EndPoints.FINALIZE_PASSWORD_RESET, requestBody)
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateOrganization(data, cb) {
    axios.put(EndPoints.UPDATE_ORGANIZATION.replace(":id", data._id), { data }, {
      headers: await this.getRequestHeaders()
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async moveWorkspacesToOrganization(data, cb) {
    axios.put(EndPoints.MOVE_WORKSPACES_TO_ORGANIZATION.replace(":id", data.organization), { data }, {
      headers: await this.getRequestHeaders()
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async deleteOrganization(id, cb) {
    axios.delete(EndPoints.DELETE_ORGANIZATION.replace(':id', id), {
      headers: await this.getRequestHeaders()
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async fetchOrganizationMembers(id, cb) {
    axios.get(EndPoints.ORGANIZATION_MEMBERS.replace(':id', id), {
      headers: await this.getRequestHeaders()
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async upsertOrganizationWithWorkspace(form, cb) {
    axios.post(EndPoints.UPSERT_ORGANIZATION_WITH_WORKSPACE, { form }, {
      headers: await this.getRequestHeaders()
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async fetchOrganizations(query = {}, apiKey, cb) {
    axios.get(APIService.withQueryParameters(EndPoints.MY_ORGANIZATIONS, query), {
      headers: { "X-API-Key": apiKey }
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async createNewWorkSpace(
    workSpaceName,
    firstName,
    lastName,
    workSpaceSettings,
    attributes,
    organization,
    cb
  ) {
    let requestBody = {
      name: workSpaceName,
      firstName,
      lastName,
      settings: workSpaceSettings,
      attributes,
      organization
    };
    axios
      .post(EndPoints.NEW_WORKSPACE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkSpaceName(workSpaceId, new_name, cb) {
    let requestBody = { new_name };
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_NAME.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async bulkWorkspaceNamesUpdate(data, cb) {
    axios
      .put(
        EndPoints.BULK_UPDATE_WORKSPACE_NAMES,
        { data },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkspaceTheme(workSpaceId, theme, cb) {
    let requestBody = { theme };
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_THEME.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async togglePanelData(workSpaceId, panelData, cb) {
    let requestBody = { panelData };
    axios
      .post(
        EndPoints.TOGGLE_PANEL.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchTimedPaymentData(apiKey, eventId) {
    try {
      const response = await axios.get(
        `${EndPoints.TIMED_PAYMENT_EVENT}?dataId=${eventId}`,
        {
          headers: {
            "X-API-Key": apiKey,
          },
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async fetchSimilarHashTags(
    workSpaceId,
    referenceHashTag,
    forAIWriter,
    cb
  ) {
    axios
      .get(
        `${EndPoints.SIMILAR_HASHTAGS.replace(
          ":workSpaceId",
          workSpaceId
        )}?hashTag=${encodeURIComponent(
          referenceHashTag
        )}&for_ai_writer=${forAIWriter}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchTaggableUsersOrPublicPage(workSpaceId, search, cb) {
    axios
      .get(
        `${EndPoints.TAGGABLE_USER_OR_PUBLIC_PAGE.replace(
          ":workSpaceId",
          workSpaceId
        )}?search=${search}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPixelImages(query, cb) {
    axios
      .get(`${EndPoints.GET_PEXEL_IMAGES}?query=${query}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async uploadMediaCloud(workSpaceId, parentFolder, fileData, cb) {
    axios
      .post(
        `${EndPoints.UPLOAD_MEDIA_CLOUD.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":parentFolder", parentFolder)}`,
        fileData,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchMediaCloud(workSpaceId, parentFolder, cb) {
    axios
      .get(
        `${EndPoints.FETCH_MEDIA_CLOUD.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":parentFolder", parentFolder)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getPostlyCloudUsage(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.GET_POSTLY_CLOUD_USAGE.replace(
          ":workSpaceId",
          workSpaceId
        )}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getPostlyCloudParentGenerations(parentFolder, cb) {
    axios
      .get(
        `${EndPoints.GET_POSTLY_CLOUD_FOLDER_PARENTAL_GENERATIONS.replace(
          ":parentFolder",
          parentFolder
        )}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteMediaCloud(id, requestBody, cb) {
    axios
      .post(
        `${EndPoints.DELETE_MEDIA_CLOUD.replace(":workSpaceId", id)}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createFolderMediaCloud(workSpaceId, parentFolder, fileData, cb) {
    axios
      .post(
        `${EndPoints.CREATE_FOLDER_MEDIA_CLOUD.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":parentFolder", parentFolder)}`,
        fileData,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async moveDocsToFolderMediaCloud(
    workSpaceId,
    parentFolder,
    filesData,
    cb
  ) {
    axios
      .patch(
        `${EndPoints.MOVE_DOCS_MEDIA_CLOUD.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":parentFolder", parentFolder)}`,
        { files: filesData },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPixelVideos(query, cb) {
    axios
      .get(`${EndPoints.GET_PEXEL_VIDEOS}?query=${query}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //shutterstock
  static async fetchShutterStockVideos(query, cb) {
    axios
      .get(`${EndPoints.GET_SHUTTERSTOCK_VIDEOS}?name=${query}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async fetchShutterStockImages(query, cb) {
    axios
      .get(`${EndPoints.GET_SHUTTERSTOCK_IMAGES}?name=${query}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        console.log(e);
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  //

  static async removeImageBackground(requestBody, cb) {
    axios
      .post(`${EndPoints.REMOVE_IMAGE_BACKGROUND}`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getLinkPreview(requestBody, cb) {
    axios
      .post(`${EndPoints.GET_LINK_PREVIEW}`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async adminGetAllUsers(
    requestBody,
    cb
  ) {
    axios
      .post(
        EndPoints.ADMIN_GET_ALL_USERS,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async updatePostEmailNotificationSettings(
    workSpaceId,
    notificationChoices,
    cb
  ) {
    let requestBody = { notificationChoices };
    axios
      .post(
        EndPoints.POST_EMAIL_NOTIFICATIONS.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async updateWorkspaceTwitterApiKey(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_TWITTER_API_KEY.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async preprocessWorkspaceTransfer(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.PREPROCESS_WORKSPACE_TRANSFER.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async finalizeWorkspaceTransfer(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.FINALIZE_WORKSPACE_TRANSFER.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async turnOffAllWorkSpacePostsAndCampaigns(workSpaceId, turnOff, cb) {
    let requestBody = { turnOff };
    axios
      .post(
        EndPoints.TURN_OFF_ALL_POSTS_AND_CAMPAIGNS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkSpaceCustomDomains(workSpaceId, custom_domains, cb) {
    let requestBody = { custom_domains }
    axios
      .put(EndPoints.UPDATE_WORKSPACE_CUSTOM_DOMAINS.replace(":workSpaceId", workSpaceId), requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async prepareCustomDomainForWorkspace(workSpaceId, custom_domain, cb) {
    let requestBody = { custom_domain }
    axios
      .post(EndPoints.PREPARE_CUSTOM_DOMAIN_FOR_WORKSPACE.replace(":workSpaceId", workSpaceId), requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async setDefaultTimeZoneForPublishing(
    workSpaceId,
    selectedTimeZone,
    cb
  ) {
    let requestBody = { selectedTimeZone };
    axios
      .post(
        EndPoints.DEFAULT_TIMEZONE_FOR_PUBLISHING.replace(
          ":workspace_id",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async pausePost(workSpaceId, postId, pause, cb) {
    let requestBody = { pause };
    axios
      .post(
        EndPoints.PAUSE_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkSpaceLogo(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.UPDATE_WORKSPACE_LOGO.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateUserAvatar(requestBody, cb) {
    axios
      .put(EndPoints.USER_AVATAR, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateAccountLogo(requestBody, cb) {
    axios
      .put(EndPoints.ACCOUNT_LOGO, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateAccountDetails(requestBody, cb) {
    axios
      .post(EndPoints.UPDATE_ACCOUNT_DETAILS, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkSpaceSettings(workSpaceId, settings, cb) {
    let requestBody = { settings };
    console.log("🚀 ~ APIService ~ updateWorkSpaceSettings ~ requestBody:", requestBody)
    axios
      .post(
        EndPoints.UPDATE_WORKSPACE_SETTINGS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateLastViewedWorkSpace(workSpaceId, cb) {
    let requestBody = { workSpaceId };
    axios
      .post(EndPoints.UPDATE_LAST_VIEWED_WORKSPACE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteWorkSpace(workSpaceId, cb) {
    axios
      .delete(EndPoints.DELETE_WORKSPACE.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async archiveWorkSpaces(workSpaceIds, archive, cb) {
    let requestBody = { workSpaceIds, archive };
    axios
      .post(EndPoints.ARCHIVE_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteWorkspaces(workSpaceIds, cb) {
    let requestBody = { workSpaceIds };
    axios
      .post(EndPoints.DELETE_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteWorkSpaceSynchronously(workSpaceId) {
    try {
      let response = await axios.delete(
        EndPoints.DELETE_WORKSPACE.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw e;
    }
  }

  static async deleteAccount(cb) {
    axios
      .delete(EndPoints.DELETE_ACCOUNT, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async addNewWorkSpacePlatform(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.ADD_NEW_WORKSPACE_PLATFORM.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async postNewScheduleCleanUpDate(requestBody, cb) {
    axios
      .post(EndPoints.POST_NEW_SCHEDULED_RESOURCE_CLEAN_UP_DATE, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async disconnectMultiplePageOrGroup(
    workSpaceId,
    requestBody,
    cb
  ) {
    axios
      .post(
        EndPoints.DISCONNECT_MULTIPLE_PAGE_OR_GROUP.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async connectMultiplePageOrGroup(
    workSpaceId,
    requestBody,
    cb
  ) {
    axios
      .post(
        EndPoints.CONNECT_MULTIPLE_PAGE_OR_GROUP.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async connectPageOrGroup(
    workSpaceId,
    platform_identifier,
    user_id,
    page_or_group_id,
    requestBody,
    cb
  ) {
    axios
      .post(
        EndPoints.CONNECT_PAGE_OR_GROUP.replace(":workSpaceId", workSpaceId)
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_group_id", page_or_group_id)
          .replace(":user_id", user_id),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async disconnectPageOrGroup(
    workSpaceId,
    platform_identifier,
    target,
    user_id,
    page_or_group_id,
    cb
  ) {
    axios
      .delete(
        EndPoints.DISCONNECT_PAGE_OR_GROUP.replace(":workSpaceId", workSpaceId)
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_group_id", page_or_group_id)
          .replace(":target", target)
          .replace(":user_id", user_id),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async connectPageOrAccountForMessaging(
    workSpaceId,
    platform_identifier,
    user_id,
    page_or_account_id,
    requestBody,
    cb
  ) {
    axios
      .post(
        EndPoints.CONNECT_PAGE_OR_ACCOUNT_FOR_MESSAGING.replace(
          ":workSpaceId",
          workSpaceId
        )
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_account_id", page_or_account_id)
          .replace(":user_id", user_id),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async disconnectPageOrAccountFromMessaging(
    workSpaceId,
    platform_identifier,
    target,
    user_id,
    page_or_account_id,
    cb
  ) {
    axios
      .delete(
        EndPoints.DISCONNECT_PAGE_OR_ACCOUNT_FROM_MESSAGING.replace(
          ":workSpaceId",
          workSpaceId
        )
          .replace(":platform_identifier", platform_identifier)
          .replace(":page_or_account_id", page_or_account_id)
          .replace(":target", target)
          .replace(":user_id", user_id),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async removeWorkSpacePlatform(workSpaceId, identifier, userId, cb) {
    axios
      .delete(
        EndPoints.REMOVE_WORKSPACE_PLATFORM.replace(":workSpaceId", workSpaceId)
          .replace(":platform_identifier", identifier)
          .replace(":user_id", userId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async removeCorruptedPlatforms(workSpaceId, corruptedPlatforms, cb) {
    axios
      .post(
        EndPoints.REMOVE_CORRUPTED_PLATFORMS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        {
          corruptedPlatforms,
        },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        if (cb) {
          cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
        }
      })
      .catch((e) => {
        if (cb) {
          cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
        }
      });
  }

  static async fetchAllWorkSpacePosts(
    workSpaceId,
    filters,
    postStatus,
    postSearchTerm,
    skip
  ) {
    let requestQuery = { ...filters };
    if (postStatus) {
      requestQuery["post_status"] = postStatus;
    }
    if (postSearchTerm) {
      requestQuery["search_string"] = postSearchTerm;
    }
    if (skip) {
      requestQuery["page"] = 1;
      requestQuery["limit"] = skip;
    }
    let query = generateQueryFromFilterOptions(requestQuery);
    try {
      const response = await axios.get(
        `${EndPoints.ALL_POSTS.replace(":workSpaceId", workSpaceId)}?${query}`,
        {
          headers: await this.getRequestHeaders(),
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (error) {
      throw HttpErrorHandler.spitHttpErrorMsg(error);
    }
  }

  static async countWorkspacePosts(workSpaceId, criteria, cb) {
    axios
      .get(
        EndPoints.COUNT_POSTS.replace(":workSpaceId", workSpaceId).replace(
          ":criteria",
          criteria
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchAllWorkSpaceDraftContents(workSpaceId) {
    try {
      const response = await axios.get(
        `${EndPoints.FETCH_ALL_WORKSPACE_DRAFT_CONTENT.replace(
          ":workSpaceId",
          workSpaceId
        )}`,
        {
          headers: await this.getRequestHeaders(),
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (error) {
      throw HttpErrorHandler.spitHttpErrorMsg(error);
    }
  }

  static async fetchUpcomingPopularEvents(workSpaceId, cb) {
    axios
      .get(
        EndPoints.UPCOMING_POPULAR_EVENTS.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchSupportedCountries(workSpaceId, cb) {
    axios
      .get(
        EndPoints.SUPPORTED_COUNTRIES.replace(":workspace_id", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async fetchPostLimitations(workSpaceId, cb) {
    axios
      .get(
        EndPoints.POST_LIMITATIONS.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async upsertCustomEvent(workSpaceId, data, cb) {
    let requestBody = { ...data };
    axios
      .post(
        EndPoints.UPSERT_CUSTOM_EVENT_DAY.replace(":workspace_id", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteCustomEventDay(workSpaceId, eventId, cb) {
    axios
      .delete(
        EndPoints.DELETE_CUSTOM_EVENT.replace(
          ":workspace_id",
          workSpaceId
        ).replace(":eventId", eventId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async addNewLocationsToDays(workSpaceId, data, cb) {
    let requestBody = { ...data };
    axios
      .post(
        EndPoints.ADD_NEW_DAYS_LOCATION.replace(":workspace_id", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async removeLocationFromDays(workSpaceId, locationName, cb) {
    axios
      .delete(
        EndPoints.REMOVE_LOCATION_FROM_WORKSPACE_DAYS.replace(
          ":workspace_id",
          workSpaceId
        ).replace(":location", encodeURIComponent(locationName)),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchTendingTopics(workSpaceId, cb) {
    // axios
    //   .get(
    //     EndPoints.GET_AVAILABLE_TRENDS.replace(":workspace_id", workSpaceId),
    //     {
    //       headers: await this.getRequestHeaders(),
    //     }
    //   )
    //   .then((response) => {
    //     cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    //   })
    //   .catch((e) => {
    //     cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    //   });
  }

  static async fetchUserNotifications(cb) {
    axios
      .get(EndPoints.USER_NOTIFICATIONS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateNotificationReadStatus(notificationId, status, cb) {
    axios
      .put(
        `${EndPoints.READ_NOTIFICATION.replace(":id", notificationId)}`,
        {
          status: status,
        },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        if (cb) {
          cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
        }
      })
      .catch((e) => {
        if (cb) {
          cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
        }
      });
  }

  static async clearAllNotifications(cb) {
    axios
      .delete(EndPoints.CLEAR_ALL_NOTIFICATIONS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchDraftedWorkSpacePosts(workSpaceId, cb) {
    axios
      .get(`${EndPoints.DRAFTED_POSTS.replace(":workSpaceId", workSpaceId)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchAllUserDrafts(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.ALL_USER_DRAFTS.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchScheduledWorkSpacePosts(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.SCHEDULED_POSTS.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPublishedWorkSpacePosts(workSpaceId, queryString, cb) {
    axios
      .get(
        `${EndPoints.PUBLISHED_POSTS.replace(
          ":workSpaceId",
          workSpaceId
        )}?${queryString}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpacePopularPosts(workSpaceId, cb) {
    axios
      .get(`${EndPoints.POPULAR_POSTS.replace(":workSpaceId", workSpaceId)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpaceActivities(workSpaceId, cb) {
    axios
      .get(`${EndPoints.ACTIVITIES.replace(":workSpaceId", workSpaceId)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpacePlatforms(workSpaceId, cb) {
    if (!workSpaceId) {
      console.log(`WS 404`);
      cb(null, "WorkSpace ID is required");
      return;
    }
    axios
      .get(
        `${EndPoints.WORKSPACE_PLATFORMS.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchSubredditFlairs(workSpaceId, userId, subReddit, cb) {
    axios
      .get(
        `${EndPoints.SUBREDDIT_FLAIRS.replace(":workSpaceId", workSpaceId)
          .replace(":user_id", userId)
          .replace(":subreddit", subReddit)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchConnectedAccounts(workSpaceId, platformIdentifier, cb) {
    axios
      .get(
        EndPoints.CONNECTED_ACCOUNTS.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":identifier", platformIdentifier),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchConnectedSocialsCount(workSpaceId, cb) {
    axios
      .get(
        EndPoints.CONNECTED_PLATFORMS_COUNT.replace(
          ":workspace_id",
          workSpaceId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPagesAndGroups(
    activeWorkSpace,
    identifier,
    user_id,
    cb,
    query = ""
  ) {
    let activeWorkSpaceId = activeWorkSpace["_id"];
    let url = EndPoints.PLATFORMS_PAGES_AND_GROUPS.replace(
      ":workSpaceId",
      activeWorkSpaceId
    )
      .replace(":platform_identifier", identifier)
      .replace(":user_id", user_id)
      .concat(query);
    axios({
      method: "GET",
      url: url,
      headers: await this.getRequestHeaders(),
      timeout: 120000,
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUserLatestData(apiKey) {
    try {
      const response = await axios.get(EndPoints.ME, {
        headers: { "X-API-Key": apiKey },
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async fetchLatestMe(cb) {
    axios.get(EndPoints.ME, {
      headers: await this.getRequestHeaders(),
    }).then((response) => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    }).catch((e) => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
    });
  }

  static async updateLastQuery(cb) {
    axios
      .get(EndPoints.UPDATE_LAST_QUERY, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getMetricsForAdmins(cb) {
    axios
      .get(EndPoints.GET_METRICS_FOR_ADMINS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }


  static async fetchMyFingerPrint() {
    try {
      const response = await axios.get(EndPoints.MY_FINGER_PRINT, {
        headers: await this.getRequestHeaders(),
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async fetchLatestMeSync() {
    try {
      let response = await axios.get(EndPoints.ME, {
        headers: await this.getRequestHeaders(),
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async postToWorkSpace(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.POST_TO_WORKSPACE.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async uploadBulkContent(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.BULK_CONTENT_UPLOAD.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async uploadBulkWorkspaces(requestBody, cb) {
    axios
      .post(EndPoints.CREATE_BULK_WORKSPACES, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async approvePost(workSpaceId, postId, requestBody, cb) {
    axios
      .put(
        EndPoints.APPROVE_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updatePostTime(workSpaceId, postId, new_time, cb) {
    axios
      .put(
        EndPoints.POST_TIME.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        { new_time },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async newMetric(requestBody, cb) {
    if (!window.location.href?.includes("app.postly.ai")) {
      return cb(null, { message: '...m..uns' })
    }
    let { data } = requestBody;
    if (data?.userEmail) {
      if (excludeUserFromMetrics(data?.userEmail)) {
        return cb(null, { message: '...m..uns' })
      }
    }
    axios
      .post(
        EndPoints.NEW_METRIC,
        { requestBody },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async updateUserQuickResponse(requestBody) {
    try {
      const response = await axios.post(
        EndPoints.UPDATE_USER_QUICK_RESPONSE,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      );
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      throw HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }
  static async updateDraftContent(workSpaceId, postData, cb) {
    axios
      .post(
        EndPoints.UPDATE_DRAFT_CONTENT.replace(":workSpaceId", workSpaceId),
        { postData },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async updatePost(workSpaceId, postId, postData, cb) {
    axios
      .put(
        EndPoints.UPDATE_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        { postData },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async disApprovePost(workSpaceId, postId, requestBody, cb) {
    axios
      .put(
        EndPoints.DISAPPROVE_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static getOrdinalSuffix(index) {
    if (index === 0 || index === 1) {
      return " ";
    }
    return numberToWordConverter.toOrdinal(index);
  }

  static async uploadFileToFirebase({ file, filename, fileTag, fileIndex }, cb) {
    let storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(
      `${StringUtils.generateRandomString(16)}-${Date.now()}-${filename}`
    );
    let uploadTask = fileRef.put(file);
    const fileType = getFileType(filename);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        let progressText = `Uploading ${APIService.getOrdinalSuffix(
          fileIndex
        )} ${StringUtils.capitalizeString(fileType.toLowerCase())} ${fileTag ? ` for ${fileTag}` : ""
          }`;
        localStorage.setItem(
          "content_upload_progress",
          JSON.stringify({
            message: progressText,
            progress: Math.round(progress),
          })
        );
        window.dispatchEvent(new Event("storage"));
        if (progress >= 100) {
          localStorage.removeItem("content_upload_progress");
        }
      },
      (error) => {
        console.log(error);
        cb({
          statusCode: 500,
          message:
            "Sorry, an error occurred while uploading your file. Please try again later",
        }, null);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          cb(null, {
            statusCode: 200,
            message: "Upload successful",
            data: downloadURL,
          });
        });
      }
    );
  }

  static async uploadNewFile(
    _workSpaceId,
    requestBody,
    fileSize,
    fileTag,
    fileIndex
  ) {
    const formDataObj = {};
    requestBody.forEach((value, key) => (formDataObj[key] = value));
    let filename = formDataObj.file.name;
    let firebaseUpload = promisify(APIService.uploadFileToFirebase);
    try {
      let res = await firebaseUpload({
        file: formDataObj.file,
        filename,
        fileIndex,
        fileTag,
        fileSize,
      });
      return res;
    } catch (e) {
      throw "Sorry, an error occurred. Please try again";
    }
  }

  static transcodeSocket;
  static async transcodeVideo(
    workSpaceId,
    videoRequestBody,
    platform,
    videoSize = 0,
    listeners = {}
  ) {
    let videoTag = StringUtils.generateRandomString(64);
    let transcodeBaseUrl = "https://video-transcoder.postly.ai";
    if (!APIService.transcodeSocket) {
      APIService.transcodeSocket = io(transcodeBaseUrl, {
        transports: ["websocket"],
      });
    }
    let transcodeUpdateListener = (data) => {
      if (data.upload_progress) {
        listeners?.uploadProgressListener(data.upload_progress);
      }
      if (data.transcode_progress) {
        listeners?.transcodeProgressListener(data.transcode_progress);
      }
      if (data.transcode_final_result) {
        listeners?.completionListener({ data: data.transcode_final_result });
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      }
      if (data.transcode_error) {
        listeners?.errorListener(data.transcode_error);
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      }
    };
    APIService.transcodeSocket.on(videoTag, transcodeUpdateListener);
    return axios({
      method: "PUT",
      url: `${transcodeBaseUrl}/v1/:workSpaceId/:platform/convert_video`
        .replace(":workSpaceId", workSpaceId)
        .replace(":platform", platform),
      data: videoRequestBody,
      headers: await this.getRequestHeaders({
        fileSize: videoSize,
        videoTag,
      }),
    })
      .then((response) => {
        listeners?.completionListener(
          HttpSuccessDataHandler.getSuccessResponseData(response)
        );
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      })
      .catch((e) => {
        listeners?.errorListener(HttpErrorHandler.spitHttpErrorMsg(e));
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      });
  }

  static async compressVideo(
    workSpaceId,
    videoRequestBody,
    platform,
    videoSize = 0,
    listeners = {}
  ) {
    let videoTag = StringUtils.generateRandomString(64);
    let transcodeBaseUrl = "https://video-transcoder.postly.ai";
    if (!APIService.transcodeSocket) {
      APIService.transcodeSocket = io(transcodeBaseUrl, {
        transports: ["websocket"],
      });
    }
    let transcodeUpdateListener = (data) => {
      if (data.upload_progress) {
        listeners?.uploadProgressListener(data.upload_progress);
      }
      if (data.compress_progress) {
        listeners?.compressProgressListener(data.compress_progress);
      }
      if (data.compress_final_result) {
        listeners?.completionListener({ data: data.compress_final_result });
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      }
      if (data.transcode_error) {
        listeners?.errorListener(data.compress_error);
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      }
    };
    APIService.transcodeSocket.on(videoTag, transcodeUpdateListener);
    return axios({
      method: "PUT",
      url: `${transcodeBaseUrl}/v1/:workSpaceId/:platform/compress_video`
        .replace(":workSpaceId", workSpaceId)
        .replace(":platform", platform),
      data: videoRequestBody,
      headers: await this.getRequestHeaders({
        fileSize: videoSize,
        videoTag,
      }),
    })
      .then((response) => {
        listeners?.completionListener(
          HttpSuccessDataHandler.getSuccessResponseData(response)
        );
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      })
      .catch((e) => {
        listeners?.errorListener(HttpErrorHandler.spitHttpErrorMsg(e));
        APIService.transcodeSocket.off(videoTag, transcodeUpdateListener);
      });
  }

  static async draftPostToWorkSpace(workSpaceId, data, newDraft, draftId, cb) {
    let requestBody = {
      data,
      newDraft,
      draftId,
    };
    axios
      .put(
        EndPoints.DRAFT_POST.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
          onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deletePost(workSpaceId, postId, cb) {
    axios
      .delete(
        EndPoints.POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async multiDeletePosts(workSpaceId, postIds, moreData = {}, cb) {
    let requestBody = { postIds, ...moreData };
    axios
      .post(
        EndPoints.DELETE_ALL_POSTS.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteWorkSpacePostFromOnlyPostly(workSpaceId, postId, cb) {
    axios
      .delete(
        EndPoints.DELETE_POST_FROM_ONLY_POSTLY.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":post_id", postId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getWordpressPostCategories(workSpaceId, siteUrl, cb) {
    axios
      .get(
        `${EndPoints.GET_WORDPRESS_CATEGORIES.replace(
          ":workSpaceId",
          workSpaceId
        )}?siteUrl=${siteUrl}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getImageThoughServerProxy(imgUrl, sendAsDataUrl) {
    const response = await axios.get(
      `${EndPoints.PROXY_IMAGE}?imgUrl=${imgUrl}&${sendAsDataUrl ? "sendAsDataUrl=true" : ""
      }`,
      {
        headers: await this.getRequestHeaders(),
      }
    );
    return response;
  }

  static async deleteWorkSpacePostFromOnlyPlatformsItWasPublishedTo(
    workSpaceId,
    postId,
    cb
  ) {
    axios
      .delete(
        EndPoints.DELETE_POST_FROM_ONLY_PUBLISHED_PLATFORMS.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":post_id", postId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deletePostBatch(workSpaceId, batchId, cb) {
    axios
      .delete(
        EndPoints.POST_BATCH.replace(":workSpaceId", workSpaceId).replace(
          ":batch_id",
          batchId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteRecurringPost(workSpaceId, postGroupIdentifier, cb) {
    axios
      .delete(
        EndPoints.RECURRING_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_group_identifier",
          postGroupIdentifier
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchRecurringPostDetails(workSpaceId, postGroupIdentifier, cb) {
    axios
      .get(
        EndPoints.RECURRING_POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_group_identifier",
          postGroupIdentifier
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPost(workSpaceId, postId, cb) {
    axios
      .get(
        EndPoints.POST.replace(":workSpaceId", workSpaceId).replace(
          ":post_id",
          postId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpacePostCategories(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.POST_CATEGORIES.replace(":workSpaceId", workSpaceId)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async getCustomDomainLogo(domain, cb) {
    axios
      .get(
        `${EndPoints.GET_CUSTOM_DOMAIN_LOGO.replace(":domain", domain)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createNewPostCategory(workSpaceId, name, createdBy, cb) {
    let requestBody = {
      name,
      createdBy,
    };
    axios
      .post(
        `${EndPoints.NEW_POST_CATEGORY.replace(":workSpaceId", workSpaceId)}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteDraftedPost(workSpaceId, id, cb) {
    axios
      .delete(
        `${EndPoints.DELETE_DRAFTED_POST.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":id", id)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async deletePostCategory(workSpaceId, categoryId, cb) {
    axios
      .delete(
        `${EndPoints.DELETE_POST_CATEGORY.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":categoryId", categoryId)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async upsertNewAudienceGroup(workSpaceId, requestBody, cb) {
    axios
      .post(
        `${EndPoints.UPSERT_AUDIENCE_GROUP.replace(
          ":workSpaceId",
          workSpaceId
        )}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async updateAudienceGroup(workSpaceId, group_id, requestBody, cb) {
    axios
      .post(
        `${EndPoints.UPDATE_AUDIENCE_GROUP.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":group_id", group_id)}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async countAudienceGroup(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.AUDIENCE_GROUP_COUNT.replace(
          ":workSpaceId",
          workSpaceId
        )}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchAudienceGroups(workSpaceId, cb) {
    axios
      .get(
        `${EndPoints.AUDIENCE_GROUP_FETCH.replace(
          ":workSpaceId",
          workSpaceId
        )}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteAudienceGroup(workSpaceId, groupId, cb) {
    axios
      .delete(
        `${EndPoints.DELETE_AUDIENCE_GROUP.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":group_id", groupId)}`,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createNewPinterestBoard(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.CREATE_NEW_PINTEREST_BOARD.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getGoogleDriveMedias(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.GET_GOOGLE_DRIVE_MEDIAS.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async disconnectGDrive(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.DISCONNECT_GOOGLE_DRIVE.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createNewPinterestBoardSection(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.CREATE_NEW_PINTEREST_BOARD_SECTION.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async redeemVoucher(code, accountId, cb) {
    let requestBody = { code, accountId };
    axios
      .post(EndPoints.REDEEM_VOUCHER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async billUser(
    annualBillingChecked,
    payment_for_new_workspace = false,
    workspace_owner_id,
    initiated_for_workspace_creation = false,
    plan_type,
    quantity,
    trial_period_days,
    verificationUrl,
    others = {},
    cb
  ) {
    let requestBody = {
      pay_annually: annualBillingChecked,
      payment_for_new_workspace,
      workspace_owner_id,
      initiated_for_workspace_creation,
      plan_type,
      quantity,
      trial_period_days,
      verificationUrl,
      others
    };
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async buyMoreAIWriterQuotas(annualBillingChecked, workspace_id, cb) {
    let requestBody = {
      pay_annually: annualBillingChecked,
      plan_type: "ExtraAICharacters",
      workspace_id,
      payment_for_extra_ai_characters: true,
    };
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async payOneOffFeeForCustomTelegramBot(workspace_id, path_param, cb) {
    let requestBody = {
      plan_type: "CustomTelegramBot",
      workspace_id,
      path_param,
      payment_for_customized_telegram_bot: true,
    };
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async payOneOffFeeForLTDUpGrade(workspace_id, path_param, quantity, cb) {
    let requestBody = {
      plan_type: "LTD UPGRADE",
      workspace_id,
      path_param,
      quantity,
      payment_for_ltd_one_time_upgrade: true,
    };
    axios
      .post(EndPoints.BILL_USER, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPendingBotRequests(requestBody, cb) {
    axios
      .post(EndPoints.BOT_REQUESTS, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async cancelPendingBotRequest(workSpaceId, botRequestId, cb) {
    axios
      .delete(
        EndPoints.CANCEL_BOT_REQUEST.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(
          ":botRequestId",
          botRequestId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async requestForCustomizedBot(workSpaceId, requestBody, cb) {
    axios
      .put(
        EndPoints.REQUEST_FOR_CUSTOMIZED_TELEGRAM_BOT.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async saveCopy(workSpaceId, tool, text, cb) {
    axios
      .post(
        EndPoints.SAVE_COPY.replace(":workSpaceId", workSpaceId),
        { tool, text },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async saveArtCopy(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.SAVE_ART_COPY.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async removeArtCopy(workSpaceId, copyId, cb) {
    axios
      .delete(
        EndPoints.REMOVE_ART_COPY.replace(":workSpaceId", workSpaceId).replace(
          ":copy_id",
          copyId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async removeCopy(workSpaceId, copyId, cb) {
    axios
      .delete(
        EndPoints.REMOVE_COPY.replace(":workSpaceId", workSpaceId).replace(
          ":copy_id",
          copyId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async deleteAllSavedCopies(workSpaceId, cb) {
    axios
      .delete(
        EndPoints.DELETE_ALL_SAVED_COPIES.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchSavedCopies(workSpaceId, tool, cb) {
    axios
      .get(
        EndPoints.SAVED_COPIES.replace(":workSpaceId", workSpaceId).replace(
          ":tool",
          encodeURIComponent(tool)
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchAllSavedCopies(workSpaceId, cb) {
    axios
      .get(EndPoints.ALL_SAVED_COPIES.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async fetchAllSavedArtCopies(workSpaceId, cb) {
    axios
      .get(
        EndPoints.ALL_SAVED_ART_COPIES.replace(":workSpaceId", workSpaceId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async cancelSubscriptionSync(apiKey) {
    try {
      const response = await axios.delete(EndPoints.CANCEL_SUBSCRIPTION, {
        headers: {
          "X-API-Key": apiKey,
        },
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async cancelSubscription(cb) {
    axios
      .delete(EndPoints.CANCEL_SUBSCRIPTION, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async cancelAISubscriptionSync(apiKey) {
    try {
      const response = axios.delete(EndPoints.CANCEL_AI_SUBSCRIPTION, {
        headers: {
          "X-API-Key": apiKey,
        },
      });
      return HttpSuccessDataHandler.getSuccessResponseData(response);
    } catch (e) {
      return HttpErrorHandler.spitHttpErrorMsg(e);
    }
  }

  static async cancelAISubscription(cb) {
    axios
      .delete(EndPoints.CANCEL_AI_SUBSCRIPTION, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async showAttachedVoucherCode(used_by, cb) {
    axios
      .get(EndPoints.FETCH_VOURCHERS_BY_ACCOUNT, {
        params: { used_by },
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUserDraft(workSpaceId, cb) {
    axios
      .get(EndPoints.USER_DRAFT.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async generateText(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.GENERATE_TEXT.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async generateArt(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.GENERATE_ART.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async checkAIWriterUsageResumption(workSpaceId, cb) {
    axios
      .get(
        EndPoints.CHECK_AI_WRITER_USAGE_RESUMPTION.replace(
          ":workSpaceId",
          workSpaceId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async getWorkspaceOwnerAICredits(workSpaceId, cb) {
    axios
      .get(
        EndPoints.GET_WORKSPACE_OWNER_AI_CREDITS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async updateAIChatUsage(workSpaceId, promptLength, cb) {
    axios.post(EndPoints.GET_AND_CALC_AI_POINT.replace(":workSpaceId",
      workSpaceId), {
      promptLength: promptLength,
    },
      {
        headers: await this.getRequestHeaders(),
      }).then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchWorkSpaceBots(workSpaceId, platformUserId, cb) {
    axios
      .get(
        EndPoints.WORKSPACE_BOTS.replace(":work_space_id", workSpaceId).replace(
          ":platform_user_id",
          platformUserId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchTaggables(query, cb) {
    axios
      .get(`${EndPoints.TAGGABLES}?q=${encodeURIComponent(query)}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async setDefaultBot(workSpaceId, platformUserId, token, cb) {
    axios
      .post(
        EndPoints.SET_DEFAULT_BOT.replace(
          ":work_space_id",
          workSpaceId
        ).replace(":platform_user_id", platformUserId),
        {
          botToken: token,
        },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchRSSFeedContent(workSpaceId, platform_user_id, cb) {
    axios
      .get(
        EndPoints.FETCH_RSS_FEED_CONTENT.replace(
          ":workSpaceId",
          workSpaceId
        ).replace(":platform_user_id", platform_user_id),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      });
  }

  static async deleteRSSFeed(workSpaceId, feedId, cb) {
    axios
      .delete(
        EndPoints.DELETE_RSS_FEED.replace(":workSpaceId", workSpaceId).replace(
          ":feedIds",
          feedId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      });
  }

  static async upsertExistingRSSFeed(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.UPSERT_WORKSPACE_RSS_FEED.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      });
  }

  static async addNewRSSPlatform(workSpaceId, requestBody, cb) {
    axios
      .post(
        EndPoints.ADD_NEW_RSS_FEED.replace(":workSpaceId", workSpaceId),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      });
  }

  static async postRSSFeedContent(workSpaceId, platformUserId, cb) {
    axios
      .post(
        EndPoints.POST_RSS_FEED_TO_PLATORM.replace(":workSpaceId", workSpaceId),
        { platform_user_id: platformUserId },
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      });
  }

  static async updateFeedContent(workSpaceId, cb) {
    axios.get(EndPoints.UPDATE_FEED_CONTENT.replace(":workSpaceId", workSpaceId),
      {
        headers: await this.getRequestHeaders()
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      })
  }

  static async fetchPublishingFeed(workSpaceId, cb) {
    axios.get(`${EndPoints.WORKSPACE}/:workSpaceId/publishing_feed`.replace(":workSpaceId", workSpaceId), {
      headers: await this.getRequestHeaders()
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      })
  }

  static async fetchPublishingFeedStatus(workSpaceId, feedId, cb) {
    axios.get(`${EndPoints.WORKSPACE}/:workSpaceId/:feed_id/publishing_feed_status`
      .replace(":workSpaceId", workSpaceId)
      .replace(':feed_id', feedId), {
      headers: await this.getRequestHeaders()
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((error) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
      })
  }

  static async updateAutoSaveDraft(workSpaceId, requestBody, cb) {
    axios
      .patch(
        EndPoints.UPDATE_AUTO_SAVE_DRAFT_STATUS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkspaceContentPlannerSettings(workSpaceId, requestBody, cb) {
    axios
      .patch(
        EndPoints.UPDATE_WORKSPACE_CONTENT_PLANNER_SETTINGS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkspaceLanguageAndRegionSettings(workSpaceId, requestBody, cb) {
    axios
      .patch(
        EndPoints.UPDATE_WORKSPACE_LANGUAGE_AND_REGION_SETTINGS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateWorkspaceMoreOptionsSettings(workSpaceId, requestBody, cb) {
    axios
      .patch(
        EndPoints.UPDATE_WORKSPACE_MORE_OPTIONS_SETTINGS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async applyToAllWorkspaces(workSpaceId, requestBody, type, cb) {
    axios
      .patch(
        `${EndPoints.APPLY_TO_ALL_WORKSPACES_SETTINGS.replace(
          ":workSpaceId",
          workSpaceId
        )}?type=${type}`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchAutoSaveDraftStatus(workSpaceId, cb) {
    axios
      .get(
        EndPoints.FETCH_AUTO_SAVE_DRAFT_STATUS.replace(
          ":workSpaceId",
          workSpaceId
        ),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async shortenLink(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/shorten_link`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async bulkShortenLinks(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/bulk_shorten_link`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //bitly integration link shortener
  static async bitlyLinkShortener(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/bitly`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  //save link
  static async saveLink(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/save_link`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  //create folder
  static async createLinkFolder(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/create_link_folder`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //fetch folder
  static async fetchFolders(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/fetch_folders`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //get auto shortned linnk status
  static async getAutoShortenedLinkStatus(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_auto_shortned_status`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //get token
  static async getBitlyToken(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_bitly_token`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //add token
  static async addBitlyToken(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/integration/add_token`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //generate_review_link
  static async generateReviewLink(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/review_link_generation/generate_review_link`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //fetch google business
  static async fetchGoogleBusiness(businessName, cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/review_link_generation/fetch_business?name=${businessName}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }



  //set token
  static async setBitlyToken(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/integration/set_token`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //update status
  static async toggleAutoShortenLinks(requestBody, cb) {
    axios
      .patch(`${EndPoints.LINK_SHORTENER}/update_status`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //get my library
  static async fecthMyLibrary(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_my_library`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //rename item
  static async renameItem(requestBody, id, cb) {
    axios
      .patch(`${EndPoints.LINK_SHORTENER}/rename_item/${id}`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //delete item
  static async deleteItem(id, cb) {
    axios
      .delete(`${EndPoints.LINK_SHORTENER}/delete_item/${id}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //delete multiple item
  static async deleteMultipleItems(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/delete_multiple_items`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //fecth domains
  static async fetchDomains(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_domains`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //add new domain
  static async addDomain(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/add_domain`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //remove new domain
  static async removeDomainFromWorkSpace(requestBody, cb) {
    axios
      .patch(`${EndPoints.LINK_SHORTENER}/delete_domain`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async updateCustomEmailUsername(workSpaceId, domainId, requestBody, cb) {
    axios
      .post(EndPoints.UPDATE_CUSTOM_EMAIL_USERNAME.replace(":workSpaceId", workSpaceId).replace(":domainId", domainId), requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async prepareCustomDomainForEmailSupport(workSpaceId, domainId, cb) {
    axios
      .post(EndPoints.PREPARE_CUSTOM_DOMAIN_FOR_EMAIL_SUPPORT.replace(":workSpaceId", workSpaceId).replace(":domainId", domainId), {},
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async destroyCustomDomainForEmailSupport(workSpaceId, domainId, cb) {
    axios
      .delete(EndPoints.DESTROY_CUSTOM_DOMAIN_FOR_EMAIL_SUPPORT.replace(":workSpaceId", workSpaceId).replace(":domainId", domainId),
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async removeDomainFromWorkSpaceForWhiteLabel(workSpaceId, domainId, cb) {
    axios
      .delete(EndPoints.REMOVE_DOMAIN_FROM_WORKSPACE.replace(":workSpaceId", workSpaceId).replace(":domainId", domainId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  //save qrcode to database
  static async saveQrcode(requestBody, cb) {
    axios
      .post(`${EndPoints.LINK_SHORTENER}/save_qrcode`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  //fetch qrcode to database
  static async getQrcodes(cb) {
    axios
      .get(`${EndPoints.LINK_SHORTENER}/get_qrcode`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getNewGDriveAccessToken(requestBody, cb) {
    axios
      .post(`${EndPoints.GET_GOOGLE_ACCESS_TOKEN}`, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async getRequestHeaders(moreHeaders = {}) {
    let headers = {};
    let loggedInUser = getLoggedInUserFromLocalStorage();
    if (loggedInUser) {
      headers["X-API-Key"] = loggedInUser["api_key"];
    }
    let allHeaders = { ...headers, ...moreHeaders };
    return allHeaders;
  }


  static async fetchUserInvoiceHistory(cb) {
    axios
      .get(EndPoints.TRANSACTION_HISTORY, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchTopPosts(
    workSpaceId,
    platformId,
    requestBody,
    platformIdentifier,
    cb
  ) {
    axios
      .post(
        `${EndPoints.ANALYTICS.replace(":workSpaceId", workSpaceId)
          .replace(":platformId", platformId)
          .replace(":identifier", platformIdentifier)}/posts`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPageStatistics(
    workSpaceId,
    platformId,
    requestBody,
    platformIdentifier,
    cb
  ) {
    axios
      .post(
        `${EndPoints.ANALYTICS.replace(":workSpaceId", workSpaceId)
          .replace(":platformId", platformId)
          .replace(":identifier", platformIdentifier)}/page_statistics`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchUsersByCountry(
    workSpaceId,
    platformId,
    requestBody,
    platformIdentifier,
    cb
  ) {
    axios
      .post(
        `${EndPoints.ANALYTICS.replace(":workSpaceId", workSpaceId)
          .replace(":platformId", platformId)
          .replace(":identifier", platformIdentifier)}/demographic_stats`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchFollowersTimeline(
    workSpaceId,
    platformId,
    requestBody,
    platformIdentifier,
    cb
  ) {
    axios
      .post(
        `${EndPoints.ANALYTICS.replace(":workSpaceId", workSpaceId)
          .replace(":platformId", platformId)
          .replace(":identifier", platformIdentifier)}/followers_trend`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPageInsights(
    workSpaceId,
    platformId,
    requestBody,
    platformIdentifier,
    cb
  ) {
    axios
      .post(
        `${EndPoints.ANALYTICS.replace(":workSpaceId", workSpaceId)
          .replace(":platformId", platformId)
          .replace(":identifier", platformIdentifier)}/page_insights`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchPageProfileOverview(
    workSpaceId,
    platformId,
    requestBody,
    platformIdentifier,
    cb
  ) {
    axios
      .post(
        `${EndPoints.ANALYTICS.replace(":workSpaceId", workSpaceId)
          .replace(":platformId", platformId)
          .replace(":identifier", platformIdentifier)}/profile`,
        requestBody,
        {
          headers: await this.getRequestHeaders(),
        }
      )
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async connectOrDisconnectUserFromSocialInbox(requestBody, cb) {
    try {
      const response = await axios.post(
        `${EndPoints.SOCIAL_INBOX_BASE}/connect_or_disconnect_account`,
        requestBody
      );
      const responseData =
        HttpSuccessDataHandler.getSuccessResponseData(response);
      cb(responseData, null);
    } catch (error) {
      const errorMessage = HttpErrorHandler.spitHttpErrorMsg(error);
      cb(null, errorMessage);
    }
    // return new Promise(async (resolve, reject) => {
    //   try {
    //     const response = await axios.post(`${EndPoints.SOCIAL_INBOX_BASE}/connect_or_disconnect_account`, requestBody, {
    //       withCredentials: true,
    //     });
    //     console.log("🚀 ~ file: api_service.js:3180 ~ APIService ~ promise ~ response:", response);
    //     const responseData = HttpSuccessDataHandler.getSuccessResponseData(response);
    //     resolve(responseData);

    //     cb(responseData, null);
    //     // If a callback function is provided, execute it with the response
    //     if (cb && typeof cb === 'function') {
    //     }
    //   } catch (error) {
    //     console.error("Error in connectOrDisconnectUserFromSocialInbox:", error?.message);
    //     reject(HttpErrorHandler.spitHttpErrorMsg(error));

    //     // If a callback function is provided and there's no error in the callback section, execute it with the error
    //     cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
    //     if (cb && typeof cb === 'function' && !HttpErrorHandler.spitHttpErrorMsg(error)) {
    //     }
    //   }
    // });

    // Return the promise for further chaining if needed
    // return promise;
  }

  static async sendMessageFromSocialInbox(chatRoomId, requestBody, cb) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${EndPoints.SOCIAL_INBOX_BASE}/${chatRoomId}/send_message`,
          requestBody
        )
        .then((response) => {
          const responseData =
            HttpSuccessDataHandler.getSuccessResponseData(response);
          console.log("responseData", responseData);
          cb(responseData, null);
          resolve(responseData);
        })
        .catch((error) => {
          const errorMessage = HttpErrorHandler.spitHttpErrorMsg(error);
          console.log("errorMessage", errorMessage);
          cb(null, errorMessage);
          reject(errorMessage);
        });
    });
  }

  static async getAllSocialInboxUsers(cb) {
    try {
      const response = await axios.get(`${EndPoints.SOCIAL_INBOX_BASE}/users`, {
        withCredentials: true,
      });

      // Call the callback function with the success response data and no error
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    } catch (error) {
      // Call the callback function with null data and the error
      cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
    }
  }

  static async getUserRoomMessages(userId, cb) {
    try {
      const response = await axios.get(
        `${EndPoints.SOCIAL_INBOX_BASE}/${userId}/room_messages`,
        {
          withCredentials: true,
        }
      );

      // Call the callback function with the success response data and no error
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
    } catch (error) {
      // Call the callback function with null data and the error
      cb(null, HttpErrorHandler.spitHttpErrorMsg(error));
    }
  }

  // static async getUserRoomMessages(userId) {
  //   return axios.get(`${EndPoints.SOCIAL_INBOX_BASE}/${userId}/room_messages`, {
  //     // headers: await this.getRequestHeaders(),
  //     withCredentials: true,  // Include this line

  //   });
  // }
  static async generateReport(requestBody, cb) {
    axios
      .post(`${EndPoints.EXPORT_ANALYTICS_REPORT}`, requestBody, {
        header: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async fetchCustomDomainsInWorkSpace(workSpaceId, cb) {
    axios
      .get(EndPoints.FETCH_ALL_WORKSPACE_CUSTOM_DOMAINS.replace(":workSpaceId", workSpaceId), {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async connectSelfHostedWordpress(workSpaceId, requestBody, cb) {
    axios
      .post(EndPoints.CONNECT_SELF_HOSTED_WORDPRESS_SITE.replace(':workSpaceId', workSpaceId), requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async clearPostBacklogs(requestBody, cb) {
    axios
      .post(EndPoints.CLEAR_POST_BACKLOG, requestBody, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }

  static async createConnectedAccount(businessType, cb) {
    axios
      .get(`${EndPoints.CREATE_CONNECTED_ACCOUNT}?business_type=${businessType}`, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchStripeConnectedAccount(cb) {
    axios
      .get(EndPoints.CONNECTED_ACCOUNT, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchConnectedAccountBalanceTransactions(cb) {
    axios
      .get(EndPoints.GET_CONNECTED_ACCOUNT_BALANCE_TRANSACTIONS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchConnectedAccountPayouts(cb) {
    axios
      .get(EndPoints.GET_CONNECTED_ACCOUNT_PAYOUTS, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async updateConnectedAccountDetails(cb) {
    axios
      .get(EndPoints.UPDATE_CONNECTED_ACCOUNT, {
        headers: await this.getRequestHeaders(),
      })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async fetchSavedAIConversations(workSpaceId, cb) {
    axios.get(EndPoints.GET_SAVED_AICONVERSATION.replace(":workSpaceId", workSpaceId), {
      headers: await this.getRequestHeaders(),
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async platformVideoUploadLimit(workSpaceId, cb) {
    axios.get(EndPoints.PLATFORM_VIDEO_UPLOAD_LIMIT.replace(":workSpaceId", workSpaceId), {
      headers: await this.getRequestHeaders(),
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async fetchUserPlatformsCountStatement(workSpaceId, cb) {
    axios.get(EndPoints.GET_USER_PLATFORM_COUNT_STATEMENT.replace(":workSpaceId", workSpaceId), {
      headers: await this.getRequestHeaders(),
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }
  static async fetchAllInvoices(cb) {
    axios.get(EndPoints.FETCH_ALL_INVOICE, {
      headers: await this.getRequestHeaders(),
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
      })
  }

  static async saveAIConversation(messages, topic, id, model, workSpaceId, cb) {

    let body;
    if (id) {
      body = {
        title: topic,
        id,
        model,
        messages
      }
    }
    else {
      body = {
        title: topic,
        model,
        messages
      }
    }
    // console.log("body",body)
    axios.post(EndPoints.SAVE_OR_UPDATE_AICONVERSATION.replace(":workSpaceId", workSpaceId), body, {
      headers: await this.getRequestHeaders(),
    })
      .then((response) => {
        cb(HttpSuccessDataHandler.getSuccessResponseData(response), null);
      })
      .catch((e) => {
        cb(null, HttpErrorHandler.spitHttpErrorMsg(e));
      });
  }
  static async saveApiKey(workSpaceId, product, value, removeKey, cb) {
    axios.post(EndPoints.SAVE_AI_KEY.replace(":workSpaceId", workSpaceId), {
      product,
      value,
      removeKey: removeKey || false
    }, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      console.log("apiservice error", e)
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }
  static async savePrompt(workSpaceId, promptData, cb) {
    axios.post(EndPoints.SAVE_PROMPT.replace(":workSpaceId", workSpaceId), {
      title: promptData?.title,
      description: promptData?.description,
      tags: promptData?.tags,
      prompt: promptData?.prompt,
      ispublic: promptData?.ispublic,
      source: promptData?.source
    }, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }

  static async getPrompts(workSpaceId, search = "", status = "public", cb) {
    axios.get(EndPoints.GET_PROMPTS.replace(":workSpaceId", workSpaceId) + "?status=" + status + "&search=" + search, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }

  static async generateTextV2(workSpaceId, prompt, isPersonalKey, model, conversationId = null, cb) {
    axios.post(EndPoints.GENERATE_TEXT_V2.replace(":workSpaceId", workSpaceId), {
      prompt,
      model,
      isPersonalKey,
      conversationId
    }, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }
  static async translateTextV2(workSpaceId, prompt, languageToTranslateFrom, language, cb) {
    axios.post(EndPoints.TRANSLATE_TEXT.replace(":workSpaceId", workSpaceId), {
      prompt,
      languageToTranslateFrom,
      languageToTranslateTo: language
    }, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }

  static async addNewProduct(requestBody, cb) {
    axios.post(EndPoints.ADD_NEW_PRICING_PRODUCT, requestBody, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }
  static async fetchAllProductsAndPrices(cb) {
    axios.get(EndPoints.FETCH_ALL_PRODUCTS_AND_PRICES, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }
  static async updateWhiteLabelProduct(productId, requestBody, cb) {
    axios.patch(EndPoints.UPDATE_WHITE_LABEL_PRODUCT.replace(":id", productId), requestBody, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }
  static async initProductCheckoutSession(requestBody, cb) {
    axios.post(EndPoints.INIT_STRIPE_CONNECT_CHECKOUT_SESSION, requestBody, {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }

  static async redirectUserToStripeConnectAccountOnboardingLink(cb) {
    axios.get(EndPoints.REDIRECT_USER_TO_STRIPE_CONNECT_ACCOUNT_ONBOARDING_LINK,{
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }

  static async fetchWebsiteProductPlans(website, cb){
    axios.get(EndPoints.RETRIEVE_WEBSITE_PRODUCT_PLANS.replace(":website", website), {
      headers: await this.getRequestHeaders(),
    }).then(response => {
      cb(HttpSuccessDataHandler.getSuccessResponseData(response), null)
    }).catch(e => {
      cb(null, HttpErrorHandler.spitHttpErrorMsg(e))
    });
  }
}
